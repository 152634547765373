.welcome-message {
    text-align: justify;
}

.welcome-banner {
    background-color: #F4D03F;
    background-image: linear-gradient(142deg, #F4D03F 0%, #16A085 100%);
}

.welcome-header {
    color: black;
}

.green-text {
    color:green;
    font-weight: bold;
}

.objective-text {
    text-align: justify;
}